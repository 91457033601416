.sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
}

.title {
    color: white !important;
    text-align: center;
    margin: 10px;
    height: 80px;
}